import React from 'react';
import loadable from '@loadable/component';

import appScreenshotVideo from 'assets/images/app-notification-video.mp4';

const FadeIn = loadable(() => import('../common/FadeIn'));

const AppInfo = () => (
  <div className='info-block'>
    <div className='container'>
      <div className='info-block__grid'>
        <div className='info-block__media'>
          <figure className='info-block__video'>
            <FadeIn>
              <video
                playsInline
                webkit-playsinline='true'
                autoPlay
                muted
                loop
              >
                <source src={appScreenshotVideo} type='video/mp4' />
              </video>
            </FadeIn>
          </figure>
        </div>
        <div className='info-block__content'>
          <h2 className='info-block__title'>Get notified when the air goes awry.</h2>
          <div className='info-block__description'>
            <p>Since air is, you know, invisible, it’s hard to know when it might be unsafe to breathe—until now. </p>
          </div>
          <div className='info-block__card-grid'>
            <div className='info-block__card'>
              <h3 className='info-block__card-title'>
                Indoor
                <br />
                {' '}
                Air Alerts
              </h3>
              <div className='info-block__card-description'>
                <p>Get a heads up if there’s anything funky in the house, from high VOCs to rapidly rising AQI to the possibility of mold.</p>
              </div>
            </div>
            <div className='info-block__card'>
              <h3 className='info-block__card-title'>
                Outdoor
                <br />
                {' '}
                Air Alerts
              </h3>
              <div className='info-block__card-description'>
                <p>Mila lets you know when it’s a good idea to batten down the hatches and stay inside, due to poor air quality in your area.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AppInfo;
